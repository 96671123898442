// adapted from http://smasue.github.io/pygments-darcula
$foreground: #A9B7C6;
$background: #2B2B2B;
$selectionForeground: #a9b7c6;
$selectionBackground: #214283;

// global
$operators: #a9b7c6;
$strings: #6A8759;
$names: #A9B7C6;
$keywords: #CC7832;
$constant: #9876AA;
$varAndProp: #A9B7C6;
$numbers: #6897BB;
$tag: #BBB529;
$attributes: #A9B7C6;
$comments: #808080;
$field: #9876AA;
$linenos: #808080;
//$niceRed: #BF626B;

@mixin rounded($radius: 4px) {
  -webkit-border-radius : $radius;
  -moz-border-radius : $radius;
  border-radius : $radius;
}

code.highlighter-rouge {
    //color: $foreground;
    background-color: #EEEEEE;
    font-family: $font-primary;
    //font-family: monospace, monospace;
    font-size: 100%;//1em;
    padding: 2px;
}

.highlight {
  color: $foreground;
  background-color: $background;
  @include rounded(4px);
  ::selection {
    background-color: $selectionBackground;
    color: $selectionForeground;
  }
  .hll {
    background-color: #49483e;
  }
  .c {
    color: $comments;
  } /* Comment */
  .err {
    color: #960050;
    background-color: #1e0010;
  } /* Error */
  .k {
    color: $keywords;
  } /* Keyword */
  .l {
    color: $numbers;
  } /* Literal */
  .n {
    color: $varAndProp;
  } /* Name */
  .o {
    color: $operators;
  } /* Operator */
  .p {
    color: $varAndProp;
  } /* Punctuation */
  .cm {
    color: $comments;
  } /* Comment.Multiline */
  .cp {
    color: $comments;
  } /* Comment.Preproc */
  .c1 {
    color: $comments;
  } /* Comment.Single */
  .cs {
    color: $comments;
  } /* Comment.Special */
  .ge {
    font-style: italic;
  } /* Generic.Emph */
  .gs {
    font-weight: bold;
  } /* Generic.Strong */
  .kc {
    color: $keywords;
  } /* Keyword.Constant */
  .kd {
    color: $keywords;
  } /* Keyword.Declaration */
  .kn {
    color: $operators;
  } /* Keyword.Namespace */
  .kp {
    color: $keywords;
  } /* Keyword.Pseudo */
  .kr {
    color: $keywords;
  } /* Keyword.Reserved */
  .kt {
    color: $keywords;
  } /* Keyword.Type */
  .ld {
    color: $strings;
  } /* Literal.Date */
  .m {
    color: $numbers;
  } /* Literal.Number */
  .s {
    color: $strings;
  } /* Literal.String */
  .na {
    color: $attributes; // lexer doesn know the difference if it's a field, method call or .class, so I handrolled js script
  } /* Name.Attribute */
  .nb {
    color: $varAndProp;
  } /* Name.Builtin */
  .nc {
    color: $names;
  } /* Name.Class */
  .no {
    color: $constant;
    font-style: italic;
  } /* Name.Constant */
  .nd {
    color: $tag;
  } /* Name.Decorator */
  .ni {
    color: $varAndProp;
  } /* Name.Entity */
  .ne {
    color: $names;
  } /* Name.Exception */
  .nf {
    color: $names;
  } /* Name.Function */
  .nl {
    color: $varAndProp;
  } /* Name.Label */
  .nn {
    color: $varAndProp;
  } /* Name.Namespace */
  .nx {
    color: $names;
  } /* Name.Other */
  .py {
    color: $varAndProp;
  } /* Name.Property */
  .nt {
    color: $tag;
  } /* Name.Tag */
  .nv {
    color: $varAndProp;
  } /* Name.Variable */
  .ow {
    color: $operators;
  } /* Operator.Word */
  .w {
    color: $varAndProp;
  } /* Text.Whitespace */
  .mf {
    color: $numbers;
  } /* Literal.Number.Float */
  .mh {
    color: $numbers;
  } /* Literal.Number.Hex */
  .mi {
    color: $numbers;
  } /* Literal.Number.Integer */
  .mo {
    color: $numbers;
  } /* Literal.Number.Oct */
  .sb {
    color: $strings;
  } /* Literal.String.Backtick */
  .sc {
    color: $strings;
  } /* Literal.String.Char */
  .sd {
    color: $strings;
  } /* Literal.String.Doc */
  .s2 {
    color: $strings;
  } /* Literal.String.Double */
  .se {
    color: $numbers;
  } /* Literal.String.Escape */
  .sh {
    color: $strings;
  } /* Literal.String.Heredoc */
  .si {
    color: $strings;
  } /* Literal.String.Interpol */
  .sx {
    color: $strings;
  } /* Literal.String.Other */
  .sr {
    color: $strings;
  } /* Literal.String.Regex */
  .s1 {
    color: $strings;
  } /* Literal.String.Single */
  .ss {
    color: $strings;
  } /* Literal.String.Symbol */
  .bp {
    color: $varAndProp;
  } /* Name.Builtin.Pseudo */
  .vc {
    color: $varAndProp;
  } /* Name.Variable.Class */
  .vg {
    color: $varAndProp;
  } /* Name.Variable.Global */
  .vi {
    color: $field;
  } /* Name.Variable.Instance */
  .il {
    color: $numbers;
  } /* Literal.Number.Integer.Long */

  .gh {
  } /* Generic Heading & Diff Header */
  .gu {
    color: $comments;
  } /* Generic.Subheading & Diff Unified/Comment? */
  .gd {
    color: $operators;
  } /* Generic.Deleted & Diff Deleted */
  .gi {
    color: $names;
  } /* Generic.Inserted & Diff Inserted */
  .l-Scalar-Plain {
    color: $names;
  }

  /* line numbers */
//   .lineno {
//     border-right: solid 1px $linenos;
//     color: $linenos;
//     padding-right: 5px;
//   }
}

