// Fonts
$font-primary: 'Lato', Helvetica, sans-serif;

// Colors
// $accent:      #22b3eb; // original blue
// $accent:      #1B8EC0; // original blue lower value
// $accent:      #177BA6; // original blue even lower value
// $accent:      #9876AA; // intellij purple
$accent:      #CC7832; // Intellij Java orange

// $accent-alt:  #5ec5f0;
// $accent-alt:  #DAA06D; // Intellij Java orange + same SV shift as original
$accent-alt:  #E6A368; // Intellij Java orange + same SV shift as original + extra V

$gray-darker: #333;
$gray-dark:   #3d3d3d;
$gray:        #818181;
$gray-light:  #c6c6c6;
