/**
 * 8. Animations
 */
@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(0);
        transform:  translateY(0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.site-header,
.primary {
    -webkit-animation-duration: 0.33s;
    animation-duration: 0.33s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

.site-header {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.primary {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    animation-delay: 0s;
}

.sidebar {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    animation-delay: 0s;
}
